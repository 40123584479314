@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.font-face-gm {
  font-family: "Formula1";
}

.pointer:hover{
  cursor: pointer;
}


a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

.card-wrapper {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #fff38a;
  font-weight: 400;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #060447;
  height: 100vh;
}

.card {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card-container-infos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 2rem;
  height: 500px;
}
.card-container-infos .infos-title img {
  margin-bottom: 24px;
}
.card-container-infos .infos-title h2 {
  font-weight: 700;
  font-size: 32px;
}
.card-container-infos .infos-title p {
  padding-top: 1rem;
}
.card-container-infos .infos-title .logos {
  width: 80px;
  display: flex;
  overflow: hidden;
}
.card-container-infos .infos-title .logos .logo-1 {
  transform: translateX(100%);
  transition: 0.2s ease-in-out;
}
.card-container-infos .infos-title .logos .logo-1.active {
  transform: translateX(0);
}
.card-container-infos .infos-title .logos .logo-2 {
  transform: translateX(0);
  transition: 0.2s ease-in-out;
}
.card-container-infos .infos-title .logos .logo-2.active {
  transform: translateX(-100%);
}
.card-container-infos .infos-title .logos .logo-3 {
  transform: translateX(-100%);
  transition: 0.2s ease-in-out;
}
.card-container-infos .infos-title .logos .logo-3.active {
  transform: translateX(-200%);
}
.logo-4.active {
  transform: translateX(-300%);
  transition: 0.2s ease-in-out;
}
.card-container-infos .infos-title .titles {
  overflow: hidden;
  height: 38px;
}
.card-container-infos .infos-title .titles .title-1 {
  transform: translateY(100%);
  transition: 0.2s ease;
}
.card-container-infos .infos-title .titles .title-1.active {
  transform: translateY(-10%);
}
.card-container-infos .infos-title .titles .title-2 {
  transform: translateY(0);
  transition: 0.2s ease;
}
.card-container-infos .infos-title .titles .title-2.active {
  transform: translateY(-110%);
}
.card-container-infos .infos-title .titles .title-3 {
  transform: translateY(-100%);
  transition: 0.2s ease;
}
.card-container-infos .infos-title .titles .title-3.active {
  transform: translateY(-210%);
}
.card-container-infos .infos-title .titles .title-4 {
  transform: translateY(-150%);
  transition: 0.2s ease;
}
.card-container-infos .infos-title .titles .title-4.active {
  transform: translateY(-310%);
}
.card-container-infos .infos-title .descriptions {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 75px;
}
.card-container-infos .infos-title .descriptions .description-1 {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.2s ease;
}
.card-container-infos .infos-title .descriptions .description-1.active {
  opacity: 1;
  transform: translateY(0);
}
.card-container-infos .infos-title .descriptions .description-2 {
  transform: translateY(0);
  opacity: 0;
  transition: 0.2s ease;
}
.card-container-infos .infos-title .descriptions .description-2.active {
  opacity: 1;
  transform: translateY(-100%);
}
.card-container-infos .infos-title .descriptions .description-3 {
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.2s ease;
}
.card-container-infos .infos-title .descriptions .description-3.active {
  opacity: 1;
  transform: translateY(-200%);
}
.card-container-infos .infos-title .descriptions .description-4 {
  transform: translateY(-200%);
  opacity: 0;
  transition: 0.2s ease;
}
.card-container-infos .infos-title .descriptions .description-4.active {
  opacity: 1;
  transform: translateY(-300%);
}
.card-container-infos .infos-year h1 {
  font-size: 175px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  height: 200px;
  overflow: hidden;
}
.card-container-infos .infos-year h1 span {
  font-weight: 700;
}
.card-container-infos .infos-year .numbers {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-container-infos .infos-year .numbers .number-1 {
  transform: translateY(100%);
  transition: 0.2s ease;
  opacity: 0;
}
.card-container-infos .infos-year .numbers .number-1.active {
  opacity: 1;
  transform: translateY(0);
}
.card-container-infos .infos-year .numbers .number-2 {
  transform: translateY(0);
  transition: 0.2s ease;
  opacity: 0;
}
.card-container-infos .infos-year .numbers .number-2.active {
  opacity: 1;
  transform: translateY(-100%);
}
.card-container-infos .infos-year .numbers .number-3 {
  transform: translateY(-100%);
  transition: 0.2s ease;
  opacity: 0;
}
.card-container-infos .infos-year .numbers .number-3.active {
  opacity: 1;
  transform: translateY(-200%);
}
.card-container-infos .infos-year .numbers .number-4 {
  transform: translateY(-200%);
  transition: 0.2s ease;
  opacity: 0;
}
.card-container-infos .infos-year .numbers .number-4.active {
  opacity: 1;
  transform: translateY(-300%);
}
.card-container-img {
  max-width: 349px;
  height: 500px;
  display: flex;
  overflow: hidden;
}
.card-container-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.card-container-img .bg-1 {
  transform: translateX(100%);
  transition: 0.2s ease-in-out;
}
.card-container-img .bg-1.active {
  transform: translateX(0);
}
.card-container-img .bg-2 {
  transform: translateX(0);
  transition: 0.2s ease-in-out;
}
.card-container-img .bg-2.active {
  transform: translateX(-100%);
}
.card-container-img .bg-3 {
  transform: translateX(-100%);
  transition: 0.2s ease-in-out;
}
.card-container-img .bg-3.active {
  transform: translateX(-200%);
}
.card-container-img .bg-4 {
  transform: translateX(-185%);
  transition: 0.2s ease-in-out;
}
.card-container-img .bg-4.active {
  transform: translateX(-285%);
}
.card-evolution {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}
.card-evolution-arrow img {
  display: inline-block;
  cursor: pointer;
  margin: 0 0.5rem;
}
.card-evolution .circles {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-evolution .circles .line {
  position: relative;
  height: 2px;
  width: 20%;
  background: rgba(255, 243, 138, 0.5);
}
.card-evolution .circles .line::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: left;
  transform: scaleX(0);
  background: #fff38a;
  transition: 0.2s ease-in-out;
}
.card-evolution .circles .line.active {
  position: relative;
}
.card-evolution .circles .line.active.active::before {
  transform: scaleX(100%);
}
.card-evolution .circles .circle {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 243, 138, 0.5);
  overflow: hidden;
}
.card-evolution .circles .circle::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  background: #fff38a;
  transition: 0.2s ease-in-out;
}
.card-evolution .circles .circle.active {
  position: relative;
}
.card-evolution .circles .circle.active::before {
  background: #fff38a;
  width: 100%;
}

@media (min-width: 1010px) and (max-width: 12120px) {
  .card-container-infos .infos-title .titles .title-4 {
    transform: translateY(0%);
  }
  .card-container-infos .infos-title .titles .title-4.active {
    transform: translateY(-420%);
    font-size: 22px;
  }
}
@media screen and (max-width: 1285px) {
  .card {
    width: 80%;
  }
}
@media screen and (max-width: 1090px) {
  .card {
    width: 85%;
  }
}
@media screen and (max-width: 940px) {
  .card {
    width: 95%;
  }
}
@media (min-width: 841px) and (max-width: 1100px) {}

@media screen and (max-width: 840px) {
  .card {
    width: 95%;
  }
  .card-container-infos {
    width: 45%;
    margin-right: 0;
  }
  .card-container-infos .infos-title {
    width: 100%;
  }
  .card-container-infos .infos-title .descriptions {
    width: auto;
  }
  .card-container-infos .infos-year {
    margin-top: 175px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .card-container-infos .infos-year h1 {
    font-size: 80px;
    height: 90px;
  }
  .card-container-img {
    width: 45%;
  }
  .card-container-img img {
    width: 100%;
  }
}

@media (min-width: 428px) and (max-width: 840px) {
  .card-container-infos {
      width: 65%;
      margin-right: 0;
  }
  .card-container-img {
    height: 300px;
  }
  .card-container-infos .infos-title h2 {
    font-size: 16px;
  }
  .card-container-infos .infos-title .descriptions {
    font-size: 12px;
  }
  .card-evolution .circles .circle {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .arrow-left, .arrow-right{
    width: 25%;
  }
  .card-container-infos .infos-title .titles .title-1 {
    transform: translateY(-100%);
  }
  .card-container-infos .infos-title .titles .title-1.active {
    transform: translateY(-10%);
  }
  .card-container-infos .infos-title .titles .title-2 {
    transform: translateY(100%);
  }
  .card-container-infos .infos-title .titles .title-3 {
    transform: translateY(200%);
  }
  .card-container-infos .infos-title .titles .title-3.active {
    transform: translateY(-220%);
  }
  .card-container-infos .infos-title .titles .title-4 {
    transform: translateY(300%);
  }
  .card-container-infos .infos-title .titles .title-4.active {
    transform: translateY(-250%);
  }
  .card-container-infos .infos-title .descriptions .description-1.active {
    transform: translateY(-30%);
  }
  .card-container-infos .infos-title .descriptions .description-2.active {
    transform: translateY(-130%);
}
  .card-container-infos .infos-title .descriptions .description-3.active {
    transform: translateY(-230%);
  }
  .card-container-infos .infos-title .descriptions .description-4.active {
    transform: translateY(-300%);
  }
}

@media screen and (max-width: 640px) {
  .card-container-img {
    height: 300px;
  }
  .card-container-infos .infos-title h2 {
    font-size: 14px;
  }
  .card-container-infos .infos-title .descriptions {
    font-size: 14px;
  }
  .card-evolution .circles .circle {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .arrow-left, .arrow-right{
    width: 25%;
  }
  .card-container-infos .infos-title .titles .title-4 {
    transform: translateY(-150%);
  }
  .card-container-infos .infos-title .titles .title-3.active {
    transform: translateY(-350%);
  }
  .card-container-infos .infos-title .descriptions .description-3.active {
    opacity: 1;
    transform: translateY(-250%);
  }
  .card-container-infos .infos-title .descriptions .description-4.active {
    opacity: 1;
    transform: translateY(-360%);
  }
}